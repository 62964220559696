// import { FlexBox } from "@twilio/flex-webchat-ui";
import React from "react";
// import ChatUIEditor from "./components/ChatUIEditor";
import CloseButton from "./components/CloseButton";
import MinimizeButton from "./components/MinimizeButton";

/* const style = {
  container: {
    display: "inline",
  },
  "close-button": {
    float: "right",
  },
}; */
export default class Dashboard extends React.Component {
  // here we can add stuff when chat window is opened
  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
        id="container"
      >
        <CloseButton
          key="close-chat"
          manager={this.props.manager}
          webchatServiceDomain={this.props.appConfig.webchatServiceDomain}
        />
        <MinimizeButton key="mimize-chat" />
      </div>
    );
  }
}

// <div style={{fontSize: '10px'}}>Chat</div>