import DOMPurify from 'dompurify';

// Use DomPurify to sanitize HTML to prevent XSS attacks.
const renderSanitizedHTML = (html) => {
    if (!html) return;

    return { __html: DOMPurify.sanitize(html) };
}

export default renderSanitizedHTML;
