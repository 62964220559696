import React from "react";
import axios from "axios";
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import "./PreEngagementForm.css";
import renderSanitizedHTML from "../util";

// import Select from "react-select";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
// import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from "@material-ui/core/MenuItem";

export default class PreEngagementForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      language: "",
      firstName: "",
      lastName: "",
      email: "",
      bookingNumber: 0,
      mainReason: "Main Contact Reason",
      subreason: "",
      subReasons: "",
      subDisabled: true,
      validationFailReason: [],
      ipAddress: ''
    };
  }

  componentDidMount(){
   
      axios.get('https://jp8f9akg21.execute-api.eu-west-1.amazonaws.com/poc/ip-whitelist')
      .then(response => {
        this.setState({ipAddress: response.data})
      }).catch(error => console.log)
      
  }

  validateInput() {
    let { email, bookingNumber, firstName, lastName, mainReason, subreason } = this.state;
    let error={}, valid = true;
    if (!firstName || firstName.length < 2) {
      error["first_name"] = this.props.announcements.first_name_error_message;
      this.setState({ validationFailReason: error });
      valid=false;
    }
    if (!lastName || lastName.length < 2) {
      error["last_name"] = this.props.announcements.last_name_error_message;
      this.setState({ validationFailReason: error });
      valid=false;
    }
    if (bookingNumber.length && bookingNumber.length !== 10) {
      error["booking_number"] = this.props.announcements.booking_number_error_message;
      this.setState({ validationFailReason: error });
      valid=false;
    }
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let validEmail = re.test(String(email).toLowerCase());
    if ((!email.replace(' ', '').length) || email.length > 40 || !validEmail) {
      error["email"] = this.props.announcements.email_error_message;
      this.setState({ validationFailReason: error });
      valid= false;
    }
    if(!mainReason || mainReason === "Main Contact Reason") {
      error["main_reason"] = this.props.announcements.category_error_message;
      this.setState({ validationFailReason: error });
      valid= false;
    }
    if(!subreason) {
      error["sub_reason"] = this.props.announcements.sub_category_error_message;
      this.setState({ validationFailReason: error });
      valid= false;
    }
    return valid;
  }
  onSubmit(e) {
    e.preventDefault();
    let {
      firstName,
      lastName,
      email,
      bookingNumber,
      mainReason,
      subreason,
      ipAddress
    } = this.state;
    let resp = this.validateInput();
    if (resp) {
      FlexWebChat.Actions.invokeAction("StartEngagement", {
        formData: {
          friendlyName: firstName,
          firstName: firstName,
          lastName: lastName,
          email: email,
          bookingNumber: bookingNumber,
          mainContactReason: this.props.announcements.contact_reasons_map[mainReason],
          mainContactReasonSf: this.props.announcements.contact_reasons_sf_map[mainReason],
          subContactReason: this.props.announcements.contact_reasons_map[subreason],
          subContactReasonSf: this.props.announcements.contact_reasons_sf_map[subreason],
          ipAddress: ipAddress,
          subsidiary: this.props.subsidiary
        },
      });
    } else {
    }
  }

  handleMainChange = (event) => {
    let contactReasons = this.props.announcements.contactReasons;
    let mainReason = event.target.value;
    if (mainReason && mainReason !== "Main Contact Reason") {
      this.setState(
        {
          mainReason: mainReason,
          subReasons: contactReasons[mainReason],
          subDisabled: false,
        },
        () => {
        }
      );
    }
  };

  handleSubChange = (event) => {
    this.setState({
      subreason: event.target.value,
    });
  };

  mainSelected = () => {
    return this.state.mainReason === "";
  };

  selectedReason = () => {
    return this.state.mainReason;
  };

  getSubOptions = () => {
    let contactReasons = this.props.announcements.contactReasons;
    let selected = this.selectedReason();
    if (selected !== "Main Contact Reason") {
      let subreasons = contactReasons[selected];
      let reasons = subreasons.map(this.makeOption);
      let placeholder = this.makePlaceholder('Sub')
      reasons.unshift(placeholder);
      return reasons;
    } else {
      return [];
    }
  };

  getMainOptions = () => {
    let contactReasons = this.props.announcements.contactReasons;
    let reasons = Object.keys(contactReasons).map(this.makeOption);
    let placeholder = this.makePlaceholder('Main')
    reasons.unshift(placeholder);
    return reasons;
  };
  makePlaceholder = (type) => {
      let placeholders = this.props.announcements.placeholders;
      let placerholder_value = placeholders.main_contact_reason;
      if (type === 'Sub'){
        placerholder_value = placeholders.sub_contact_reason;
      }
      return (
        <MenuItem key='placeholder' value="" disabled>
          <em style={{ color: "#9E9E9E" }}>{placerholder_value}</em>
        </MenuItem>
      );
  };

  makeOption = (option) => {
    return (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    );
  };

  addFirstName = (e) => {
    this.setState({
      firstName: e.target.value,
    });
  };
  addLastName = (e) => {
    this.setState({
      lastName: e.target.value,
    });
  };
  addBookingNumber = (e) => {
    const bookingNumber = e.target.value.replace(/[,.]/gi, '');
    this.setState({
      bookingNumber,
    });
  };
  addEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  render() {
    let placeholders = this.props.announcements.placeholders;
    // console.log(placeholders, "placeholder");
    if (!this.props) return <div></div>;
    return (
      <div id="pre-engagement">
        <div id="notice">
          {/* <h3>{this.props.announcements.notice.first_line}</h3> */}
          <p id="secondAnnouncement">
            {this.props.announcements.notice.second_line || ""}
          </p>
        </div>
        <FormControl id="form_1">
          <div className="inputInfo">{placeholders.first_name}<sup>*</sup></div>
          <Input
            required={true}
            className="field"
            id="first-name-field"
            placeholder=""
            onChange={this.addFirstName}
          />
          {
            (this.state.validationFailReason["first_name"])? 
              <FormHelperText className="myHelperText">
                {this.state.validationFailReason["first_name"]}
              </FormHelperText>
            : ""
          }
          <div className="inputInfo">{placeholders.last_name}<sup>*</sup></div>
          <Input
            required={true}
            className="field"
            id="last-name-field"
            placeholder=""
            onChange={this.addLastName}
          />
          {
            (this.state.validationFailReason["last_name"])?
            <FormHelperText className="myHelperText">
              {this.state.validationFailReason["last_name"]}
            </FormHelperText> : ""
          }
          <div className="inputInfo">{placeholders.email}<sup>*</sup></div>
          <Input
            required={true}
            id="e-mail-field"
            placeholder=""
            onChange={this.addEmail}
            className="field"
          />
          {
            (this.state.validationFailReason["email"])?
            <FormHelperText className="myHelperText">
              {this.state.validationFailReason["email"]}
            </FormHelperText> : ""
          }
          
          <div className="inputInfo">{placeholders.main_contact_reason}<sup>*</sup></div>
          <Select
            name="main-contact-reason"
            //defaultValue="Main Contact Reason"
            required={true}
            aria-placeholder={placeholders.main_contact_reason}
            placeholder=""
            style={{ width: '190px', minWidth: '190px' }}
            value={this.state.mainReason}
            onChange={(e) => this.handleMainChange(e)}
          >
            {this.getMainOptions()}
          </Select>
          {
            (this.state.validationFailReason["main_reason"])?
            <FormHelperText className="myHelperText">
              {this.state.validationFailReason["main_reason"]}
            </FormHelperText> : ""
          }
        </FormControl>
        <FormControl id="form_2">
        <div className="inputInfo">{placeholders.sub_contact_reason}<sup>*</sup></div>
          <Select
            name="sub-contact-reason"
            required={true}
            disabled={this.state.subDisabled}
            value={this.state.subreason}
            onChange={(e) => this.handleSubChange(e)}
            style={{ width: '190px', minWidth: '190px' }}
            placeholder=""
          >
            {this.getSubOptions()}
          </Select>
          {
            (this.state.validationFailReason["sub_reason"])?
            <FormHelperText className="myHelperText">
              {this.state.validationFailReason["sub_reason"]}
            </FormHelperText> : ""
          }
          <div className="inputInfo">{placeholders.booking_number}</div>
          <Input
            id="booking-number-field"
            aria-describedby="sample"
            placeholder=""
            onChange={this.addBookingNumber}
            className="field"
            type="number"
          />
          {
            (this.state.validationFailReason["booking_number"])?
            <FormHelperText className="myHelperText">
              {this.state.validationFailReason["booking_number"]}
            </FormHelperText> : ""
          }
          
          <div className="inputInfo" dangerouslySetInnerHTML={renderSanitizedHTML(placeholders.terms_and_conditions)}></div>

          <Button id="submit-button" onClick={this.onSubmit}>
            {this.props.announcements.submit_button}
          </Button>
        </FormControl>
      </div>
    );
  }
}
