import React from 'react';
import * as FlexWebChat from "@twilio/flex-webchat-ui";

// import Url from 'url'
import Axios from 'axios';

export default class CloseButton extends React.Component {

  /**
   * onClick handler, ends the chat and invokes the minimize action
   */
  async handleClick() {
    await this.endChat();
    FlexWebChat.Actions.invokeAction("MinimizeChat");
    // ends chat for user
    // API call put task in wrap up
    // task_sid
  }
  
  // Run the Serverless Function to end the chat channel and task
  async endChat() {
    // console.log(this.props)
    let webchatDomain =  this.props.webchatServiceDomain;

    try{
      let state = this.props.manager.store.getState();

      let channel_status =
        state.flex?.session?.channelSid &&
        state.flex?.chat?.channels?.hasOwnProperty(
          state.flex.session.channelSid
        ) &&
        state.flex.chat.channels[state.flex.session.channelSid]?.source
          ?.attributes?.status;

      if (
        state.flex &&
        state.flex.session &&
        state.flex.session.channelSid &&
        !(channel_status === 'INACTIVE')
      ) {
        let channelSid = state.flex.session.channelSid;
        // Build out the config blocks for Axios
        let axiosBody = { channelSid: channelSid };
        let axiosOptions = { headers: { "Content-Type": "application/json" } };
        // let url = 'https://'+webchatDomain + '/populate-chat-with-task-sid';
        // console.log(url)
        // await Axios.post(url, axiosBody, axiosOptions)
        let url = "https://" + webchatDomain + "/end-chat";
        // Make it happen!
        return Axios.post(url, axiosBody, axiosOptions);
      }
    }catch(e){
      console.log(e)
      return null;
    }
    

  }
  /**
   * Builtin React method called to render this component
   */
  render() {
    let style = {
      cursor: "pointer"
    }
    return <span style={style} onClick={this.handleClick.bind(this)}>
      <FlexWebChat.Icon icon="Close" />
    </span>
  }
}
