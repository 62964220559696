import React from "react";
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import PreEngagementForm from './components/PreEngagementForm'
import axios from 'axios';
import Dashboard from "./Dashboard";


const GetComponent = ({text, languageData}) => {
    let chat_ends = languageData.announcements.csat_survey_text.replace("__CSAT_SURVEY_URL__", text)
    chat_ends = chat_ends.replace('<a ', '<a style="color: rgb(140, 211, 65)" ')
    chat_ends = chat_ends.replace('<button ', '<button style="background-color:#FFAE00; width: 180px; padding: 12px 12px; color: white; border: none; font-weight: 800; cursor: pointer" ')
    return <div style={{textAlign: 'center', padding: '0 20px 20px 20px'}} dangerouslySetInnerHTML={{__html: chat_ends}} />
}


class App extends React.Component {
  state = {
    active: "",
  };
  
  FLIXBUS_SUBSIDIARY = 'flixbus';
  FLIXTRAIN_SUBSIDIARY = 'flixtrain';

  constructor(props) {
    super(props);
    const { configuration }          = props;
    this.notifCounter                = 0;
    this.docTitle                    = document.title;
    this.flashTimer                  = undefined;
    this.notif                       = undefined;
    this.options                     = {};
    this.isNotificationInitialised   = false;
    this.manager                     = undefined;
    // A safe default.
    this.csat_survey_url             = undefined;

    this.getData().then((resp) => {
      this.propData                  = resp.data;
      this.subsidiary                = this.getSubsidiary()
      configuration.context.isOpen   = this.propData.isOpen;
      configuration.context.language = this.propData.languageData.language;
      configuration.context.locale   = this.propData.languageData.locale;
      FlexWebChat.Manager.create(configuration)
      .then(async(manager) => {

        let {languageData, isOpen}       = this.propData;
        this.manager                     = manager;
        this.changeStringTemplates(manager, languageData, isOpen)
        
        this.morphComponents(manager, configuration);
        
        this.setState({ manager });
        let resp = await this.initialisePreEngagement(manager, isOpen, languageData, this.subsidiary);
        if(resp) {
          this.enableApp();
        }
        if(manager.chatClient) {
          this.systemMessageBackground(manager, languageData);
        }
        FlexWebChat.Actions.addListener("afterStartEngagement", (payload) => {
          if(Notification.permission === "granted") {
            this.initialiseNotification(this.manager);
          }
          if(this.manager.chatClient) {
            this.systemMessageBackground(this.manager, languageData);
          }
        });
        FlexWebChat.Actions.on("afterToggleChatVisibility", (payload) => {
          const { isEntryPointExpanded } = manager.store.getState().flex.session;
          if (isEntryPointExpanded) {
            this.getNotificationPermission();
          }
        })
      })
      .catch((error) => {
        console.log(error);
      });
    });
       
  }

  playSound = (audioFile) => {
    try {
      let alertSound = new Audio(audioFile);
      alertSound.play();
    } catch (error) {
      console.log("Error while playing sound: " + error);
    }
  }

  notifyWithSound = (message, manager) => {
    let customerIdentity = manager.store.getState().flex.session.tokenPayload.identity;
    if (customerIdentity !== message.author) {
      const audioFile = `https://${window.appConfig.webchatServiceDomain}/assets/media/incomingMessage.mp3`;
      this.playSound(audioFile);
    } else {
      const audioFile = `https://${window.appConfig.webchatServiceDomain}/assets/media/outgoingMessage.mp3`;
      this.playSound(audioFile);
    }
  }

  systemMessageBackground = (manager, languageData) => {
    // for the previously loaded messages
    setTimeout(() => {
      let messageBubbles = document.getElementsByClassName("Twilio-MessageBubble-UserName");
        messageBubbles.forEach((item) => {
            if (item.innerText === "system") {
              item.innerText = languageData.announcements.system;
            }
        });
    }, 500);
    let channelSid = manager.store.getState().flex.session.channelSid;
    manager.chatClient.getChannelBySid(channelSid).then((channel) => {
      channel.on('messageAdded', (message) => {
        this.notifyWithSound(message, manager);
        let csat_survey_url = message.state.attributes.csat_survey_url;        
        if (csat_survey_url && !csat_survey_url.includes(this.csat_survey_url)) {
          console.error(`csat_survey_url == ${csat_survey_url}`);
          if (!this.csat_survey_url) {
            FlexWebChat.MessageCanvasTray.Content.add(<GetComponent text={csat_survey_url} languageData={languageData} key="csat-survey" />);
          } else {
            FlexWebChat.MessageCanvasTray.Content.replace(<GetComponent text={csat_survey_url} languageData={languageData} key="csat-survey" />);
          }
          this.csat_survey_url = csat_survey_url
        }

        let messageBubbles = document.getElementsByClassName("Twilio-MessageBubble-UserName");
        messageBubbles.forEach((item) => {
            if (item.innerText === "system") {
              item.innerText = languageData.announcements.system;
            }
        });
       });
    });
  }

  getNotificationPermission = () => {
    // setting the inbuilt notification to disabled state
    FlexWebChat.Notifications.handlers.browser.enabled = false;
    // if the browser doesn't support notification
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      // console.log("Permission is already granted");
      if(!this.isNotificationInitialised) {
        this.initialiseNotification(this.manager);
      }
    } else if(Notification.permission !== "denied") {
      Notification.requestPermission().then((result) => {
        // console.log("User response on asking permission to show notification: ", result);
      });
    } else if(Notification.permission === "denied") {
      // console.log("permission: ", Notification.permission);
    }
  }

  initialiseNotification = (manager) => {
    this.isNotificationInitialised = true;
    // if there is no chat channel then don't do anything
    if(manager.store.getState().flex && manager.store.getState().flex.session.tokenPayload) {
    } else {
      return true;
    }
    let channelSid = manager.store.getState().flex.session.channelSid;
    let customerIdentity = manager.store.getState().flex.session.tokenPayload.identity;
    this.addMessageListener(channelSid, customerIdentity, manager);
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        // The tab has become visible so clear the Notification if it is sticky.
        
        // Notification.close();
        clearInterval(this.flashTimer);
        this.flashTimer = false;
        this.notifCounter = 0;
        document.title = this.docTitle;
      }
    });
  }

  addMessageListener = (channelSid, customerIdentity, manager) => {
    manager.chatClient.getChannelBySid(channelSid).then((channel) => {
      channel.on('messageAdded', (message) => {
        if((customerIdentity !== message.author) && (document.visibilityState === "hidden")) {
          this.notifCounter++;
          clearInterval(this.flashTimer);
          this.flashTimer = false;
          // update title
          if(!this.flashTimer) {
            this.flashTimer = setInterval(() => {
              if(this.docTitle === document.title) {
                document.title = `(${this.notifCounter}) ${message.body || ""}`
              } else {
                document.title = this.docTitle;
              }
            }, 500);
          }
          
          // Update notification badge
          this.options = {
            body: message.body || "",
            icon: '/public/img/logo.svg',
            id: message.sid
          }
          if(!document.hasFocus()) {
            let notif = new Notification('New Notification', this.options);
            notif.onclick = function(x) { window.focus(); this.close(); };
          }
          /* this.notif =  */
        }
      });
      
    });
  }
  initialisePreEngagement=(manager, isOpen, languageData, subsidiary) => {
    return new Promise((resolve, reject) => {
      // this.getData().then((resp)=>{
       
        // let {languageData, isOpen} = resp.data;
        // manager.strings.WelcomeMessage = "Demo text";
        // let {languageData, isOpen} = resp.data;
        // this.changeStringTemplates(manager, languageData, isOpen)
        if(isOpen){
          FlexWebChat.PreEngagementCanvas.Content.replace(
            <PreEngagementForm
              key="pre-eng"
              manager={manager}
              announcements={languageData.announcements.pre_engagement_form}
              subsidiary={subsidiary}
            />,
            { sortOrder: -100 }
            );
            resolve("done");
        }else{
          this.disableApp();
          resolve(false);
        }
    // });
    });
  }

  disableApp=()=>{
    // console.log('<--Widget Disabled -->')
    document.getElementsByClassName('Twilio-EntryPoint')[0].style.backgroundColor = '#8b8b8b'
    document.getElementsByClassName('Twilio-EntryPoint')[0].style.pointerEvents = 'none'
  }
  enableApp = () => {
    // console.log('<--Enabled Widget -->')
    document.getElementsByClassName('Twilio-EntryPoint')[0].style.backgroundColor = '#8cd341'
    document.getElementsByClassName('Twilio-EntryPoint')[0].style.pointerEvents = 'auto'
  }
  changeStringTemplates = (manager, languageData, isOpen) => {
    window.manager = manager;
    manager.strings.InputPlaceHolder = languageData.announcements.type_your_message_placeholder;
    manager.strings.TypingIndicator  = languageData.announcements.agent_is_typing;
    manager.strings.MessageCanvasTrayButton = languageData.announcements.start_a_new_chat_button;
    manager.strings.MessageCanvasTrayContent = languageData.announcements.when_chat_ends;

    manager.strings.Read = languageData.announcements.read;
    manager.strings.Today = languageData.announcements.today;

    
    
    manager.strings.PredefinedChatMessageAuthorName = "Flixbus";
    manager.strings.WelcomeMessage = isOpen
      ? this.beautify(languageData.announcements.open_hours_text, 
        (languageData.auto_translation? languageData.announcements.translation_active_notice: ""))
      : this.beautify(languageData.announcements.close_hours_text, 
        (languageData.auto_translation? languageData.announcements.translation_active_notice: ""));
    manager.strings.EntryPointTagline = isOpen
      ? languageData.announcements.entry_button_text.active
      : languageData.announcements.entry_button_text.inactive;
  };

  getSubsidiary = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const hostname = window.location.hostname;
    const pathname = window.location.pathname.toLowerCase();

    // Keep in mind, the UAT FlixTrain PSSP url look like:
    // https://*flixbus*--uat.sandbox.my.site.com/*Flixtrain*/s/?language=en_IE
    // So we need to check for the subsidiary based on the pathname.
    const isFlixtrain = params.subsidiary === this.FLIXTRAIN_SUBSIDIARY || pathname.includes(this.FLIXTRAIN_SUBSIDIARY) || hostname.includes(this.FLIXTRAIN_SUBSIDIARY);

    if (isFlixtrain) {
      return this.FLIXTRAIN_SUBSIDIARY;
    }

    return this.FLIXBUS_SUBSIDIARY;
  };

  getData = ()=> {
    let url = window.location.search;
    const urlSearchParams = new URLSearchParams(url);
    const params = Object.fromEntries(urlSearchParams.entries());
    let language = params.language || 'en_US';

    let subsidiary = this.getSubsidiary()
  
    // let baseUrl =  "webchat-service-4086-dev.twil.io"
    let baseUrl =  this.props.configuration.webchatServiceDomain;
    url = `https://${baseUrl}/language-data?language=${language}&subsidiary=${subsidiary}`;
    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', },
      mode: "cors"
    }
    try{
       return axios.get(url, options)
    //    .then((resp)=>{
    //     console.log()
    //     if(resp.status == 200){
    //       console.log('resp',resp)
    //       return resp.data
    //       // do something with data
    //     }else{
    //       return { lanaguageData :{}, isOpen: false}
    //       // let {welcomeMessage, isOpen} = getDefaultValues(); 
    //     }
    // })
    }catch(e){
      console.log(e);
      // getDefaultValues(); 
    }
    
  }
  morphComponents=(manager, configuration)=>{
    FlexWebChat.MainHeader.Content.remove("close-button");
        FlexWebChat.MainHeader.defaultProps.showImage = false;
        FlexWebChat.MainHeader.defaultProps.titleText = `Chat`;
        FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;
       
        FlexWebChat.MainHeader.Content.add(
          <Dashboard
            key="dashboard"
            manager={manager}
            appConfig={configuration}
            style={{ width: "100%" }}
          />
        );
  }

  
  

  beautify(text, autoTranslate) {
    return `<div><h3>${text.first_line}</h3> <p>${text.second_line}<p></div><br/><p>${autoTranslate}</p>`;
  }

  render() {
    // this.initialisePreEngagement
    const { manager, error } = this.state;
    if (manager) {
      return (
        <div>
          <FlexWebChat.ContextProvider manager={manager}>
            <FlexWebChat.RootContainer />
          </FlexWebChat.ContextProvider>
        </div>
      );
    }

    if (error) {
      console.error("Failed to initialize Flex Web Chat", error);
    }

    return null;
  }
}

export default App;
